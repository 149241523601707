import translatedRoutes from './translatedRoutes';

/**
 * @param {string} path
 * @param {string} previousLanguage
 * @param {string} newLanguage
 * @return {string}
 */
export const translateRoute = (path, previousLanguage, newLanguage) => {
  // Transform "fr-CH" in "fr", "fr-LU" in "fr" and "en-LU" in "en"
  const formattedNewLanguage = newLanguage.substring(0, 2);
  return translatedRoutes[previousLanguage]?.[path]?.[formattedNewLanguage] || path;
};
