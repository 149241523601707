import React from 'react';
import styled from 'styled-components';
import { variant, layout, space, typography, flexbox } from 'styled-system';
import { transparentize } from 'polished';
import { Link as LinkRouter } from '../../utils/router';
import { bool, node, oneOf, number } from 'prop-types';
import Box from './Box';

import ArrowRightCircle from './icons/ArrowRightCircle';

const AnimatedIcon = styled(ArrowRightCircle)(
  ({ theme }) => ({
    fontSize: '14px',
    transition: 'transform .3s ease',
    marginBottom: '1px',
    verticalAlign: 'text-bottom',
    color: theme.colors.main
  }),
  ({ theme }) =>
    variant({
      prop: 'type',
      variants: {
        secondary: {
          color: theme.colors.secondary
        },
        tertiary: {
          color: theme.colors.black
        },
        quarternary: {
          color: theme.colors.greyDark
        }
      }
    })
);

const StyledLink = styled(LinkRouter)(
  ({ theme, withicon }) => ({
    display: 'inline-block',
    lineHeight: 'inherit',
    color: theme.colors.main,
    position: 'relative',
    marginRight: withicon ? '4px' : 0,
    '&:focus:before, &:hover:before': {
      background: transparentize(0.8, theme.colors.main)
    },
    '&:focus + svg, &:hover + svg': {
      transform: 'translateX(2px)'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      left: '-8px',
      transition: 'all .3s ease',
      top: '-4px',
      width: `calc(100% + 16px${withicon && ' + 18px'})`,
      height: 'calc(100% + 8px)',
      borderRadius: theme.radii[2],
      zIndex: 0,
      background: 'transparent'
    }
  }),
  ({ theme }) =>
    variant({
      prop: 'type',
      variants: {
        secondary: {
          color: theme.colors.secondary,
          '&:focus:before, &:hover:before': {
            background: transparentize(0.8, theme.colors.secondary)
          }
        },
        tertiary: {
          color: theme.colors.black,
          '&:focus:before, &:hover:before': {
            background: transparentize(0.95, theme.colors.dark)
          }
        },
        quarternary: {
          color: theme.colors.greyDark,
          '&:focus:before, &:hover:before': {
            background: transparentize(0.8, theme.colors.grey)
          }
        },
        quinary: {
          color: 'white'
        }
      }
    }),
  layout,
  space,
  typography,
  flexbox
);

const Link = ({ children, withicon, inNewWindow, type, ...linkProps }) => {
  const newWindowProps = inNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <>
      <StyledLink withicon={withicon} type={type} {...linkProps} {...newWindowProps}>
        {children}
      </StyledLink>
      {withicon && (
        <Box
          display="inline-flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="20px"
          verticalAlign="bottom">
          <AnimatedIcon type={type} />
        </Box>
      )}
    </>
  );
};

Link.propTypes = {
  type: oneOf(['primary', 'secondary', 'tertiary', 'quarternary', 'quinary']),
  children: node,
  withicon: number,
  inNewWindow: bool
};

export default Link;
