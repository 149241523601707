export default {
  fr: {
    '/freelance': {
      nl: '/freelancer',
      en: '/freelancer'
    },
    '/entreprise': {
      nl: '/bedrijf',
      en: '/company'
    },
    '/tarifs': {
      nl: '/tarieven',
      en: '/pricing'
    },
    '/simulateur-de-droits-d-auteur': {
      nl: '/berekening-auteursrechten',
      en: '/copyright-benefits-calculator'
    },
    '/partenaires': {
      nl: '/partners',
      en: '/partners'
    },
    '/faq': {
      nl: '/faq',
      en: '/faq'
    },
    '/informations-legales': {
      nl: '/juridisch-centrum',
      en: '/legal-center'
    },
    '/politique-de-confidentialite': {
      nl: '/privacy-beleid',
      en: '/privacy-policy'
    },
    '/politique-de-cookies': {
      nl: '/cookies-beleid',
      en: '/cookies-policy'
    },
    '/remontee-dalerte': {
      nl: '/klokkenluiden',
      en: '/whistleblowing-policy'
    },
    //LANDING PAGES FROM FR TO NL AND EN
    '/lp/trouver-freelance-developpeur': {
      nl: '/lp/vind-freelance-developer',
      en: '/lp/find-freelancer-developer'
    },
    '/lp/trouver-freelance-it-consultant-business-analyst': {
      nl: '/lp/vind-freelance-it-consultant-business-analist',
      en: '/lp/find-freelancer-it-consultant-business-analyst'
    },
    '/lp/trouver-freelance-it-consultant': {
      nl: '/lp/vind-freelance-it-consultant',
      en: '/lp/find-freelancer-it-consultant'
    },
    '/lp/trouver-freelance-designer-webdesigner': {
      nl: '/lp/vind-freelance-design-webdesign',
      en: '/lp/find-freelancer-designer-webdesigner'
    },
    '/lp/trouver-freelance-designer': {
      nl: '/lp/vind-freelance-design',
      en: '/lp/find-freelancer-designer'
    },
    '/lp/trouver-freelance-marketing': {
      nl: '/lp/vind-freelance-marketeer',
      en: '/lp/find-freelancer-marketeer'
    },
    '/lp/trouver-freelance-it-consultant-project-manager': {
      nl: '/lp/vind-freelance-it-consultant-project-manager',
      en: '/lp/find-freelancer-it-consultant-project-manager'
    },
    '/lp/trouver-freelance-developpeur-developpeur-web': {
      nl: '/lp/vind-freelance-developer-web-developer',
      en: '/lp/find-freelancer-developer-web-developer'
    },
    '/lp/trouver-freelance-marketing-marketing-digital': {
      nl: '/lp/vind-freelance-marketing-digitale-marketeer',
      en: '/lp/find-freelancer-marketeer-digital-marketeer'
    },
    '/lp/trouver-freelance-designer-designer-photoshop': {
      nl: '/lp/vind-freelance-design-photoshop-designer',
      en: '/lp/find-freelancer-designer-photoshop-designer'
    },
    '/lp/trouver-freelance-developpeur-developpeur-net': {
      nl: '/lp/vind-freelance-developer-net-developer',
      en: '/lp/find-freelancer-developer-net-developer'
    },
    '/lp/trouver-freelance-developpeur-developpeur-back-end': {
      nl: '/lp/vind-freelance-developer-back-end-developer',
      en: '/lp/find-freelancer-developer-back-end-developer'
    },
    '/lp/trouver-freelance-developpeur-developpeur-c-sharp': {
      nl: '/lp/vind-freelance-developer-c-sharp-developer',
      en: '/lp/find-freelancer-developer-c-sharp-developer'
    },
    '/lp/trouver-freelance-developpeur-data-scientist': {
      nl: '/lp/vind-freelance-developer-data-scientist',
      en: '/lp/find-freelancer-developer-data-scientist'
    }
  },
  nl: {
    '/freelancer': {
      fr: '/freelance',
      en: '/freelancer'
    },
    '/bedrijf': {
      fr: '/entreprise',
      en: '/company'
    },
    '/tarieven': {
      fr: '/tarifs',
      en: '/pricing'
    },
    '/berekening-auteursrechten': {
      fr: '/simulateur-de-droits-d-auteur',
      en: '/copyright-benefits-calculator'
    },
    '/partners': {
      fr: '/partenaires',
      en: '/partners'
    },
    '/faq': {
      fr: '/faq',
      en: '/faq'
    },
    '/juridisch-centrum': {
      fr: '/informations-legales',
      en: '/legal-center'
    },
    '/privacy-beleid': {
      fr: '/politique-de-confidentialite',
      en: '/privacy-policy'
    },
    '/cookies-beleid': {
      fr: '/politique-de-cookies',
      en: '/cookies-policy'
    },
    '/klokkenluiden': {
      fr: '/remontee-dalerte',
      en: '/whistleblowing-policy'
    },
    //LANDING PAGES FROM NL TO FR AND EN
    '/lp/vind-freelance-developer': {
      fr: '/lp/trouver-freelance-developpeur',
      en: '/lp/find-freelancer-developer'
    },
    '/lp/vind-freelance-it-consultant-business-analist': {
      fr: '/lp/trouver-freelance-it-consultant-business-analyst',
      en: '/lp/find-freelancer-it-consultant-business-analyst'
    },
    '/lp/vind-freelance-it-consultant': {
      fr: '/lp/trouver-freelance-it-consultant',
      en: '/lp/find-freelancer-it-consultant'
    },
    '/lp/vind-freelance-design-webdesign': {
      fr: '/lp/trouver-freelance-designer-webdesigner',
      en: '/lp/find-freelancer-designer-webdesigner'
    },
    '/lp/vind-freelance-design': {
      fr: '/lp/trouver-freelance-designer',
      en: '/lp/find-freelancer-designer'
    },
    '/lp/vind-freelance-marketeer': {
      fr: '/lp/trouver-freelance-marketing',
      en: '/lp/find-freelancer-marketeer'
    },
    '/lp/vind-freelance-it-consultant-project-manager': {
      fr: '/lp/trouver-freelance-it-consultant-project-manager',
      en: '/lp/find-freelancer-it-consultant-project-manager'
    },
    '/lp/vind-freelance-developer-web-developer': {
      fr: '/lp/trouver-freelance-developpeur-developpeur-web',
      en: '/lp/find-freelancer-developer-web-developer'
    },
    '/lp/vind-freelance-marketing-digitale-marketeer': {
      fr: '/lp/trouver-freelance-marketing-marketing-digital',
      en: '/lp/find-freelancer-marketeer-digital-marketeer'
    },
    '/lp/vind-freelance-design-photoshop-designer': {
      fr: '/lp/trouver-freelance-designer-designer-photoshop',
      en: '/lp/find-freelancer-designer-photoshop-designer'
    },
    '/lp/vind-freelance-developer-net-developer': {
      fr: '/lp/trouver-freelance-developpeur-developpeur-net',
      en: '/lp/find-freelancer-developer-net-developer'
    },
    '/lp/vind-freelance-developer-back-end-developer': {
      fr: '/lp/trouver-freelance-developpeur-developpeur-back-end',
      en: '/lp/find-freelancer-developer-back-end-developer'
    },
    '/lp/vind-freelance-developer-c-sharp-developer': {
      fr: '/lp/trouver-freelance-developpeur-developpeur-c-sharp',
      en: '/lp/find-freelancer-developer-c-sharp-developer'
    },
    '/lp/vind-freelance-developer-data-scientist': {
      fr: '/lp/trouver-freelance-developpeur-data-scientist',
      en: '/lp/find-freelancer-developer-data-scientist'
    }
  },
  en: {
    '/freelancer': {
      nl: '/freelancer',
      fr: '/freelance'
    },
    '/company': {
      nl: '/bedrijf',
      fr: '/entreprise'
    },
    '/pricing': {
      nl: '/tarieven',
      fr: '/tarifs'
    },
    '/copyright-benefits-calculator': {
      nl: '/berekening-auteursrechten',
      fr: '/simulateur-de-droits-d-auteur'
    },
    '/partners': {
      nl: '/partners',
      fr: '/partenaires'
    },
    '/faq': {
      nl: '/faq',
      fr: '/faq'
    },
    '/legal-center': {
      nl: '/juridisch-centrum',
      fr: '/informations-legales'
    },
    '/privacy-policy': {
      nl: '/privacy-beleid',
      fr: '/politique-de-confidentialite'
    },
    '/cookies-policy': {
      nl: '/cookies-beleid',
      fr: '/politique-de-cookies'
    },
    '/whistleblowing-policy': {
      nl: '/klokkenluiden',
      fr: '/remontee-dalerte'
    },
    //LANDING PAGES FROM EN TO NL AND FR
    '/lp/find-freelancer-developer': {
      nl: '/lp/vind-freelance-developer',
      fr: '/lp/trouver-freelance-developpeur'
    },
    '/lp/find-freelancer-it-consultant-business-analyst': {
      nl: '/lp/vind-freelance-it-consultant-business-analist',
      fr: '/lp/trouver-freelance-it-consultant-business-analyst'
    },
    '/lp/find-freelancer-it-consultant': {
      nl: '/lp/vind-freelance-it-consultant',
      fr: '/lp/trouver-freelance-it-consultant'
    },
    '/lp/find-freelancer-designer-webdesigner': {
      nl: '/lp/vind-freelance-design-webdesign',
      fr: '/lp/trouver-freelance-designer-webdesigner'
    },
    '/lp/find-freelancer-designer': {
      nl: '/lp/vind-freelance-design',
      fr: '/lp/trouver-freelance-designer'
    },
    '/lp/find-freelancer-marketeer': {
      nl: '/lp/vind-freelance-marketeer',
      fr: '/lp/trouver-freelance-marketing'
    },
    '/lp/find-freelancer-it-consultant-project-manager': {
      nl: '/lp/vind-freelance-it-consultant-project-manager',
      fr: '/lp/trouver-freelance-it-consultant-project-manager'
    },
    '/lp/find-freelancer-developer-web-developer': {
      nl: '/lp/vind-freelance-developer-web-developer',
      fr: '/lp/trouver-freelance-developpeur-developpeur-web'
    },
    '/lp/find-freelancer-marketeer-digital-marketeer': {
      nl: '/lp/vind-freelance-marketing-digitale-marketeer',
      fr: '/lp/trouver-freelance-marketing-marketing-digital'
    },
    '/lp/developer': {
      nl: '/lp/vind-freelance-developer',
      fr: '/lp/trouver-freelance-developpeur'
    },
    '/lp/it-consultant/business-analyst': {
      nl: '/lp/vind-freelance-it-consultant-business-analist',
      fr: '/lp/trouver-freelance-it-consultant-business-analyst'
    },
    '/lp/it-consultant': {
      nl: '/lp/vind-freelance-it-consultant',
      fr: '/lp/trouver-freelance-it-consultant'
    },
    '/lp/designer/webdesign': {
      nl: '/lp/vind-freelance-design-webdesign',
      fr: '/lp/trouver-freelance-designer-webdesigner'
    },
    '/lp/designer': {
      nl: '/lp/vind-freelance-design',
      fr: '/lp/trouver-freelance-designer'
    },
    '/lp/marketeer': {
      nl: '/lp/vind-freelance-marketeer',
      fr: '/lp/trouver-freelance-marketing'
    },
    '/lp/it-consultant/project-manager': {
      nl: '/lp/vind-freelance-it-consultant-project-manager',
      fr: '/lp/trouver-freelance-it-consultant-project-manager'
    },
    '/lp/developer/web-developer': {
      nl: '/lp/vind-freelance-developer-web-developer',
      fr: '/lp/trouver-freelance-developpeur-developpeur-web'
    },
    '/lp/marketeer/digital-marketeer': {
      nl: '/lp/vind-freelance-marketing-digitale-marketeer',
      fr: '/lp/trouver-freelance-marketing-marketing-digital'
    },
    '/lp/find-freelancer-designer-photoshop-designer': {
      nl: '/lp/vind-freelance-design-photoshop-designer',
      fr: '/lp/trouver-freelance-designer-designer-photoshop'
    },
    '/lp/find-freelancer-developer-net-developer': {
      nl: '/lp/vind-freelance-developer-net-developer',
      fr: '/lp/trouver-freelance-developpeur-developpeur-net'
    },
    '/lp/find-freelancer-developer-back-end-developer': {
      nl: '/lp/vind-freelance-developer-back-end-developer',
      fr: '/lp/trouver-freelance-developpeur-developpeur-back-end'
    },
    '/lp/find-freelancer-developer-c-sharp-developer': {
      nl: '/lp/vind-freelance-developer-c-sharp-developer',
      fr: '/lp/trouver-freelance-developpeur-developpeur-c-sharp'
    },
    '/lp/find-freelancer-developer-data-scientist': {
      nl: '/lp/vind-freelance-developer-data-scientist',
      fr: '/lp/trouver-freelance-developpeur-data-scientist'
    }
  }
};
