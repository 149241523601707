import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const ArrowRightCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.452 12.852a1.2 1.2 0 00.252-.396 1.2 1.2 0 000-.912 1.2 1.2 0 00-.252-.396l-3.6-3.6a1.205 1.205 0 10-1.704 1.704l1.56 1.548H8.4a1.2 1.2 0 100 2.4h4.308l-1.56 1.548a1.2 1.2 0 000 1.704 1.2 1.2 0 001.704 0l3.6-3.6zM24 12c0-6.627-5.373-12-12-12S0 5.373 0 12s5.373 12 12 12 12-5.373 12-12zM2.4 12a9.6 9.6 0 1119.2 0 9.6 9.6 0 01-19.2 0z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default ArrowRightCircle;
